<template>
  <basePolicy slug="privacy-policy-2"/>
</template>
<script>
import basePolicy from '@/components/basePolicy.vue'
export default {
  components:{
    basePolicy
  },
}
</script>